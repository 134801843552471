@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

html {
    min-height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/background.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
        background-image: url('../img/background@2x.png');
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
    }

    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto 0;
        width: 966px;
        padding: 30px;
    }

    .logo {
        margin-bottom: 35px;
        width: 100%;
    }

    .title {
        margin-bottom: 70px;
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        font-size: 81px;
        line-height: 120px;
        letter-spacing: -0.025em;
        text-align: center;
        color: #FFFFFF;
    }

    .call {
        margin-bottom: 20px;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -0.025em;
        text-align: center;
        color: #FFFFFF;
    }

    .underline {
        margin-bottom: 20px;
        width: 60px;
        height: 2px;
        background-color: #FFFFFF;;
    }

    .email {
        font-family: 'Oswald', sans-serif;
        font-size: 400;
        font-size: 30px;
        line-height: 45px;
        letter-spacing: -0.025em;
        text-align: center;
        color: #FFFFFF;
        a {
            text-decoration: none;
            color: #FFFFFF;
            &:hover {
                text-decoration: none;
                color: #FFFFFF; 
            }
        }
    }

    @media(max-width: 767px){

        body {
            background-image: url('../img/bg_mob.png');
            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
                background-image: url('../img/bg_mob@2x.png');
            }
        }

        .center {
            padding: 25px;
        }

        .title {
            margin-bottom: 45px;
            font-size: 50px;
            line-height: 60px;

        }
        .call {
            margin-bottom: 15px;
            font-size: 27px;
            line-height: 40px;
        }
        .underline {
            margin-bottom: 15px;
            width: 40px;
        }
        .email {
            font-size: 20px;
            line-height: 30px;
        }
    }

}